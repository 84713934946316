import clone from "lodash/clone"
import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import sortBy from "lodash/sortBy"

import participantLib from "@/lib/participant"
import sessionLib from "@/lib/session"

import {
  shuffleArray,
  getParticipationClub,
  removeMetaDataFromParticipations,
  getSessionBlocks,
  selectBlocks,
} from '@/lib/sortathletes/common'

import store from "@/store";

const createMixedOrder = (planningConfig, session) => {
  return new Promise((resolve) => {
    const options = getMixedOrderOptions(planningConfig)
    console.log(options)
    let catFilter = null
    if (options.repeatOrder) {
      catFilter = getRepeatOrderFilter(session)
    }

    const participations =
      sessionLib.getParticipationsWithExercises(session, catFilter).filter(p => p.status === 'present')
    const split = catFilter && (Object.keys(catFilter).length === 1)
    const panels = prepareMixedOrderItems(clone(participations), session, split)
    const blocks = calculateBlockQuota(getSessionBlocks(session), panels, !! catFilter)

    const assignedGroups = []

    const baseGroups = panels.map((panel, i) => {
      const baseGroup = {
        blocks: blocks[i],
        exercises: panel.partExercises,
        size: panel.partExercises.length,
      }

      prepareMixedGroups([baseGroup], options.groupLevels, assignedGroups)
      return baseGroup
    })

    let blockOrders = mergePanelBlocks(baseGroups, session)

    const bestOrder = createOrder(assignedGroups, blockOrders, options)

    if (catFilter) {
      addRepeatOrder(session, catFilter, bestOrder)
    }

    removeMetaDataFromParticipations(participations)

    resolve(bestOrder)
  })
}

const getRepeatOrderFilter = (session) => {
  if (session.sets !== 2) {
    return false;
  }

  let pass = true;
  const categories = [];
  const catFilter = {}
  // let totalCount = 0;
  session.categories.forEach(sessionCat => {
    const exercises = sessionCat.exercises.filter(sce => sce.set > 0)
    if (exercises.length !== 2) {
      return pass = false;
    }

    // TODO: check if exercises have been assigned to different panels.
    const participations = sessionLib.getParticipations(session, 0, sessionCat.categoryId);
    categories.push({
      id: sessionCat.categoryId,
      count: participations.length,
    });
  });

  if (! pass) {
    return false;
  }

  if (categories.length === 1) {
    catFilter[categories[0].id] = -1
    return catFilter
  }

  // divide categories over panels:
  let combinations = categoryCombinations(categories, 2)
  // find smallest gap between groups:
  let minGap = 999999;
  combinations = combinations.map(comb => {
    const gap = Math.abs(comb[0].count - comb[1].count)
    minGap = Math.min(minGap, gap)
    return {
      gap: gap,
      combination: comb,
    }
  })
  combinations = combinations.filter(comb =>
    comb.gap === minGap && comb.combination[0].count >= comb.combination[1].count
  )

  const index = Math.floor(Math.random() * combinations.length)
  const combination = combinations[index].combination

  combination.forEach((group, key) => {
    group.categories.forEach( category => {
      catFilter[category.id] = key+1
    })
  })

  return catFilter
}

const addRepeatOrder = (session, catFilter, blocks) => {
  const mappings = {}
  Object.keys(catFilter).forEach(categoryId => {
    const sessionCategory = session.categories.find(sc => sc.categoryId === categoryId)
    // const catExercise = sessionsCategory.exercises.find(ce => (ce.set !== catFilter[categoryId] && ce.set > 0))

    mappings[categoryId] = {}
    sessionCategory.exercises.forEach(catExercise => {
      const opposedExercise = sessionCategory.exercises.find(ce => ce.set !== catExercise.set)
      mappings[categoryId][catExercise.exerciseTypeId] = opposedExercise.exerciseTypeId
    })
  })


  const newExercises = []
  blocks.forEach(b => b.exercises.forEach(be => {
    const newExercise = { ... be }
    const set = (be.set + 1) % 2
    newExercise.set = set ? set : 2
    newExercise.exerciseTypeId = mappings[be.cat.id][be.exerciseTypeId]
    newExercises.push(newExercise)
  }))

  const blockSize = Math.ceil(newExercises.length * 2 / blocks.length)
  blocks.forEach(b => {
    const space = blockSize - b.exercises.length
    if (space > 0) {
      b.exercises = b.exercises.concat(newExercises.splice(0, space))
    }
  })
}

/**
 * Prepare data for mixed orders.
 * @param participations
 * @returns an array of panels with exercises to schedule for each panel
 */
const prepareMixedOrderItems = (participations, session, split = false) => {
  const panels = Array.from(Array(session.sets), (e, i) => ({ set: i+1, partExercises: []}))

  if (split) {
    participations = shuffleArray(participations)
  }

  participations.forEach((part, i) => {
    const participant = participantLib.getParticipant(part.part)
    const tmp = {
      part: part.part,
      club: participantLib.getClub(participant),
      cat: participantLib.getCategory(part.part),
    }

    Object.keys(part.exercises).forEach(exerciseTypeId => {
      const exercise = part.exercises[exerciseTypeId]

      if (split) {
        if ((i + 1 - exercise.set) % 2) {
          return
        }
      }
      panels[exercise.set-1].partExercises.push({
        ...tmp,
        exerciseTypeId,
        ...exercise
      })
    })
  }, [])

  return panels
}

const getMixedOrderOptions = (planningConfig) => {
  // grouping levels
  const groupLevels = []

  planningConfig.grouping.forEach((pref) => {
    switch(pref.type) {
      case 'category-stick':
        // add category grouping level
        groupLevels.push({
          type: 'category',
        })
        break
      case 'exercise-stick':
        groupLevels.push({
          type: 'exercises',
        })
    }
  })

  const clubDistance = planningConfig.clubApart
  const partDistance = planningConfig.partDistance
  /* Option about spreading of panel participations (in case number of exercises per panel is not evenly distributed):
  *  even: spread panel participants evenly over the session.
  *  start: start the session by switching panels such that the panel with the most exercises has a sequence of exercises ate the end
  *  end: switch panels at the end of the session, such that the panel with most exercises has a sequence at the start of the session.
  */
  const balancePanels = 'even'

  const repeatOrder = ! ! planningConfig.repeatOrder

  return { groupLevels, partDistance, clubDistance, balancePanels, repeatOrder }
}

const calculateBlockQuota = (blocks, panels, half) => {
  // start with distributing exercises evenly over blocks
  const multiplier = half ? 2 : 1
  const blockQuota = panels.map(p => Math.ceil(p.partExercises.length * multiplier/ blocks.length));
  let halfIndex = -1
  let zeroIndex = blocks.length

  if (half) {
    if (blocks.length % 2) {
      halfIndex = Math.floor(blocks.length / 2)
    }
    zeroIndex = Math.floor(blocks.length / 2)
  }

  return panels.map((p, i) => blocks.map((block, b_i) => {
    let quotum = blockQuota[i]
    let repeatQuotum = 0;
    if (half) {
      if (b_i === halfIndex) {
        quotum = Math.ceil(quotum / 2)
      } else if (b_i >= zeroIndex) {
        quotum = 0
      }
    }
    return createMixedBlockQuotum(block, quotum, repeatQuotum)
  }))
}

const createMixedBlockQuotum = (block, quotum) => {
  return {
    block: block,
    quotum: quotum,
    restQuotum: quotum,
    exercises: [],
  }
}

const prepareMixedGroups = (groups, groupLevels, assignedGroups) => {
  if (! groupLevels.length) {
    // no grouping left to do, assign participants random to blocks
    groups.forEach(group => assignGroupExercises(group, assignedGroups))
    return
  }

  // otherwise prepare groups on this level and next levels
  const groupLevel = groupLevels[0]
  const otherLevels = groupLevels.slice(1)

  groups.forEach(group => {
    let subGroups = []
    switch (groupLevel.type) {
      case 'category':
        subGroups = createMixedGroupsByCategory(group.exercises)
        break
      case 'exercises':
        subGroups = createMixedGroupsByExerciseType(group.exercises)
    }

    assignMixedGroupBlocks(subGroups, group.blocks)

    prepareMixedGroups(subGroups, otherLevels, assignedGroups)

    subGroups.forEach(subGroup => {
      mergeSubGroupMixedBlocks(group, subGroup)
    })

    // randomize block orders if next level has randomizeUpperLevel flag
    /* if (otherLevels[0]?.mixGroupsInBlock) {
      group.blocks.forEach(b => {
        b.participations = shuffleArray(b.participations)
      })
    }*/
  })
}

const assignGroupExercises = (group, assignedGroups) => {
  const candidates = shuffleArray(group.exercises)

  const assignedGroup = {
    candidates,
    id: assignedGroups.length,
    indexes: Array.from(Array(candidates.length), (e, i) => i)
  }

  // initPermutation(assignedGroup)

  group.blocks.forEach(block => {
    //block.exercises = exercises.splice(0, block.quotum)
    block.exercises = Array.from(Array(block.quotum), () => ({group: assignedGroup}))
  })

  // console.log(assignedGroup)

  assignedGroups.push(assignedGroup)
}

const assignMixedGroupBlocks = (groups, blocks) => {
  groups.forEach(group => {
    let rest = group.size
    const groupBlocks = []

    while (rest > 0) {
      // find first block with space
      const bs = selectBlocks(group, rest, blocks, {})

      bs.forEach(b => {
        const use = b.quotum
        groupBlocks.push(createMixedBlockQuotum(b.block.block, use))
        b.block.restQuotum -= use
        rest -= use
      })
    }

    group.blocks = groupBlocks
  })
}

/** add participants of the blocks in the subgroup to the blocks in the group */
const mergeSubGroupMixedBlocks = (group, subGroup) => {
  subGroup.blocks.forEach(subBlock => {
    const block = group.blocks.find(b => b.block.id === subBlock.block.id)
    block.exercises = block.exercises.concat(subBlock.exercises)
  })
}

const createMixedGroupsByCategory = (exercises) => {
  const groups = []

  exercises.forEach(ex => {
    let group = groups.find(i => i.category.id === ex.cat.id)

    if (! group) {
      group = {
        category: ex.cat,
        exercises: [],
        size: 0
      }
      groups.push(group)
    }

    group.exercises.push(ex)
    group.size++
  })

  return sortBy(groups, 'category.index')
}

const createMixedGroupsByExerciseType = (exercises) => {
  const groups = []
  const exerciseTypes = store.state.eventDiscipline.exerciseTypes;

  exercises.forEach(ex => {
    let group = groups.find(i => i.exerciseType.id === ex.exerciseTypeId)

    if (! group) {
      const exerciseType = exerciseTypes.find(et => et.id === ex.exerciseTypeId)
      group = {
        exerciseType,
        exercises: [],
        size: 0
      }
      groups.push(group)
    }

    group.exercises.push(ex)
    group.size++
  })

  return sortBy(groups, 'exerciseType.index')
}

const mergePanelBlocks = (groups, session) => {
  // start with even spreading of participant over the length of the session
  const blocks = getSessionBlocks(session)

  return blocks.map(block => {
    let groupBlocks = []
    groups.forEach(group => {
      const groupBlock = group.blocks.find(gb => gb.block.id === block.id)
      if (groupBlock) {
        groupBlocks.push(groupBlock)
      }
    })

    groupBlocks = sortBy(groupBlocks, a => -a.exercises.length)

    const maxSize = groupBlocks[0].exercises.length
    console.log('max size ', maxSize)
    groupBlocks.forEach(groupBlock => {
      groupBlock.skip = groupBlock.skips = Math.floor(maxSize / groupBlock.exercises.length) - 1
    })
    console.log(cloneDeep(groupBlocks))

    const exercises = []

    let left = true
    while (left) {
      groupBlocks.forEach(groupBlock => {
        if (! groupBlock.exercises.length) return

        if (groupBlock.skip) {
          groupBlock.skip--
          return
        }

        exercises.push(groupBlock.exercises.shift())
        groupBlock.skip = groupBlock.skips
      })
      left = resetGroupBlockSkips(groupBlocks)
      console.log(cloneDeep(groupBlocks))
    }

    return {
      block,
      exercises
    }
  })
}

const resetGroupBlockSkips = (groupBlocks) => {
  const maxSize = groupBlocks.reduce((max, groupBlock) => Math.max(max, groupBlock.exercises.length), 0)

  if (! maxSize) return false

  groupBlocks.forEach((groupBlock) => {
    groupBlock.skips = groupBlock.exercises.length ? Math.floor(maxSize / groupBlock.exercises.length) - 1 : 0
  })

  return true
}

const categoryCombinations = (items, groups) => {
  if (items.length <= groups) {
    const base = Array.from(Array(groups), (k,i) => i);
    items = sortBy(items, 'count').reverse()
    return [base.map(r => r <= items.length ? {count: items[r].count, categories: [items[r]]} : {count: 0, categories: []})];
  }

  let combinations = [];
  for(let i=0; i < items.length; i++) {
    const item = items[i];
    const items2 = clone(items);
    items2.splice(i, 1);
    const subCombinations = categoryCombinations(items2, groups);
    for (let g = 0; g < groups; g++) {
      const newCombinations = subCombinations.map(sc => {
        let newSc = clone(sc);
        // const newGroup = [item, ...newSc[g]];
        const newGroup = {
          count: newSc[g].count + item.count,
          categories: [item, ...newSc[g].categories],
        };
        newSc.splice(g, 1, newGroup);
        return newSc;
      });
      combinations = combinations.concat(newCombinations);
    }
  }
  return combinations;
}

const createOrder = (assignedGroups, blocks, options) => {
  const retain = 4
  const multiply = 5
  const poolSize = retain * multiply
  const iterations = 15

  // console.log('create random orders')
  let orders = sortBy(Array.from(Array(poolSize), () => createRandomOrder(assignedGroups, blocks, options)), 'cost')

  for (let i = 0; i < iterations; i++) {
    if (orders[0].cost === 0) break

    // console.log('iteration', i+1)
    orders = growSeeds(orders, retain, multiply, assignedGroups, options)
    // console.log(orders[0])
  }

  return flattenOrder(orders[0])
}

const createRandomOrder = (assignedGroups, blocks, options) => {
  assignedGroups.forEach(group => {
    group.permutation = shuffleArray(group.indexes)
  })

  const order = {
    blocks: [],
    cost: 0
  }

  forEach(blocks, block => {
    // const exercises = []
    const newBlock = {
      block: block.block,
      items: []
    }
    forEach(block.exercises, (item) => {
      const index = item.group.permutation.shift()
      newBlock.items.push({
        group: item.group,
        index,
      })
    })
    order.blocks.push(newBlock)
  })

  evaluateOrder(order, assignedGroups, options)

  return order
}

const growSeeds = (orders, retain, multiply, assignedGroups, options) => {
  const seeds = orders.slice(0, retain)
  let newOrders = []

  seeds.forEach(seed => {
    newOrders = newOrders.concat(Array.from(Array(multiply), () => {
      const newOrder = growSeed(seed)
      evaluateOrder(newOrder, assignedGroups, options)
      return newOrder
    }))
  })

  return sortBy(newOrders, 'cost')
}

const growSeed = (seed) => {
  console.log('new seed')
  const newOrder = {
    blocks: seed.blocks.map(b => {
      return {
        block: b.block,
        items: b.items.map(i => ({
          group: i.group,
          index: i.index,
          cost: i.cost,
          issues: []
        })),
      }
    }),
    cost: 0,
  }

  newOrder.blocks.forEach(block => {
    block.items.forEach(item => {
      if (item.cost && item.group.indexes.length > 1) {
        let newIndex = item.index
        do {
          newIndex = Math.floor(Math.random() * item.group.indexes.length)
        } while (newIndex === item.index)

        // find item with same index
        let swap = block.items.find(i => i.group.id === item.group.id && i.index === newIndex)
        if ( ! swap) {
          forEach(newOrder.blocks, b => {
            if (b.block.id === block.block.id) return

            swap = b.items.find(i => i.group.id === item.group.id && i.index === newIndex)
            if (swap) return false
          })
        }

        if (swap) {
          swap.index = item.index
          item.index = newIndex
          item.cost = 0
          swap.cost = 0
        }
      }
    })
  })

  return newOrder
}

/* const checkDoubles = (order) => {
  const groups = []
  order.blocks.forEach((block, blockIndex) => {
    block.items.forEach((item, itemIndex) => {
      if (! groups[item.group.id]) {
        groups[item.group.id] = []
      }

      if (groups[item.group.id][item.index]) {
        const exercise = getExercise(item)
        console.log('double', exercise.part.bib, item.group.id, item.index,
          groups[item.group.id][item.index].blockIndex, groups[item.group.id][item.index].itemIndex, blockIndex, itemIndex)
      } else {
        groups[item.group.id][item.index] = {
          blockIndex,
          itemIndex,
        }
      }
    })
  })
} */

const getExercise = (item) => item.group.candidates[item.index]

const evaluateOrder = (order, assignedGroups, options) => {
  order.blocks.forEach((block, blockIndex) => {
    block.items.forEach((item, itemIndex) => {
      evaluateOrderItem(item, itemIndex, order.blocks, blockIndex, options)
      order.cost += item.cost
    })
  })
}

const evaluateOrderItem = (item, itemIndex, blocks, blockIndex, options) => {
  item.issues = []
  item.cost = 0

  if (options.clubDistance) {
    // if (! checkClubDistance(exercises, candidate, 2)) return false
    evaluateClubDistance(item, itemIndex, blocks, blockIndex, 2)
  }

  if (options.partDistance) {
    evaluatePartDistance(item, itemIndex, blocks, blockIndex, options.partDistance)
  }
}

const evaluateClubDistance = (item, itemIndex, blocks, blockIndex, distance) => {
  const goBack = Math.min(distance, itemIndex)

  if (goBack) {
    const candidate = getExercise(item)
    const candidateClub = getParticipationClub(candidate)
    const items = blocks[blockIndex].items

    for (let i = goBack; i > 0; i--) {
      const exercise = getExercise(items[itemIndex - i])
      const exerciseClub = getParticipationClub(exercise)
      if (exerciseClub.id === candidateClub.id) {
        item.cost += 10
        item.issues.push('order.alert.clubDistance')
        return
      }
    }
  }
}

const evaluatePartDistance = (item, itemIndex, blocks, blockIndex, distance) => {
  const goBack = Math.min(distance, itemIndex)

  if (goBack) {
    const candidate = getExercise(item)
    const items = blocks[blockIndex].items

    for (let i = goBack; i > 0; i--) {
      const exercise = getExercise(items[itemIndex - i])
      if (exercise.part.id === candidate.part.id) {
        item.cost += 10
        item.issues.push('order.alert.partDistance')
        return
      }
    }
  }
}

const flattenOrder = (order) => {
  return order.blocks.map(block => {
    return {
      block: block.block,
      exercises: block.items.map(item => {
        const exercise = getExercise(item)
        return {
          ... exercise,
          issues: item.issues
        }
      })
    }
  })
}


/* const fillOutExercises = (blocks, options, bestCost) => {
  let cost = 0
  const order = []
  forEach(blocks, block => {
    // const exercises = []
    const newBlock = {
      block: block.block,
      exercises: []
    }
    forEach(block.exercises, (item) => {
      const candidate = item.group.exercises.shift()
      cost += checkCandidate(newBlock.exercises, candidate, options)
      newBlock.exercises.push(candidate)
      if (cost > bestCost) return false
    })
    order.push(newBlock)
    if (cost > bestCost) return false
  })

  return {
    order,
    cost,
  }
} */

/* const findBestOrder = (blockOrders, assignedGroups, options) => {
  console.log('best order')

  let bestOrder = null
  let bestCost = -1

  // calculate tolerance
  const count = assignedGroups.reduce((c, group) => {
    return c + group.candidates.length
  }, 0)
  bestCost = count + 1

  do {
    console.log('next permutation')
    // console.log(cloneDeep(assignedGroups))
    const {order, cost} = fillOutExercises(blockOrders, options)
    console.log('order cost', cost, bestCost)

    if (bestCost > cost) {
      bestCost = cost
      bestOrder = order
      if (bestCost === 0) break
    }

  } while (nextPermutation(assignedGroups))

  return bestOrder
} */

/* const nextPermutation = (assignedGroups) => {
  let result = false
  for (let i = assignedGroups.length-1; i >= 0 ; i--) {
    if (result) {
      console.log('reset group', i)
      assignedGroups[i].exercises = getPermutation(assignedGroups[i].candidates, assignedGroups[i].permutation)
    }
    else if (permute(assignedGroups[i])) {
      result = true
    }
  }

  return result
} */


/* const checkCandidate = (exercises, candidate, options) => {
  let cost = 0
  if (options.clubDistance) {
    // if (! checkClubDistance(exercises, candidate, 2)) return false
    cost += checkClubDistance(exercises, candidate, 2)
  }

  return cost
}

const checkClubDistance = (exercises, candidate, distance) => {
  if (exercises.length) {
    const goBack = Math.max(exercises.length - distance, 0)
    const candidateClub = getParticipationClub(candidate)

    for (let i = exercises.length-1; i >= goBack; i--) {
      const exerciseClub = getParticipationClub(exercises[i])
      if (exerciseClub.id === candidateClub.id) {
        return 10
      }
    }
  }

  return 0
} */

// Declare exports
export {
  createMixedOrder,
}
