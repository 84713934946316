import clone from "lodash/clone";
import participantLib from "@/lib/participant";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import store from "@/store";
import {mean} from "mathjs";
import moment from "moment";

/**
 * clone an array with a random element order
 * @param arr the input array
 * @returns newArr the randomized array
 */
const shuffleArray = (arr) => {
  const newArr = clone(arr)
  let currentIndex = newArr.length
  while (currentIndex !== 0) {
    const randomIndex = Math.floor(Math.random() * currentIndex--)
    const temp = newArr[currentIndex]
    newArr[currentIndex] = newArr[randomIndex]
    newArr[randomIndex] = temp
  }
  return newArr
}

const getParticipationClub = (participation) => {
  if (participation.club) return participation.club

  const participant = participantLib.getParticipant(participation)
  let club = participantLib.getClub(participant)

  if (participation.participantType === 'clubMember' && participant.trainingCenter) {
    club = {
      id: participant.trainingCenter,
      name: participant.trainingCenter,
      regionId: club.regionId
    }
  }

  participation.club = club
  return club
}

const removeMetaDataFromParticipations = (participations) => {
  participations.forEach(part => {
    delete(part.club)
    delete(part.team)
    delete(part.usedRotations)
  })
}

const getSessionBlocks = (session, set = 0) => {
  const skipSet = (set === 0)

  return sortBy(filter(store.state.blocks.items, item => {
    return item.sessionId === session.id && (skipSet || item.set === set)
  }), ['set', 'index'])
}

const selectBlocks = (group, size, blocks, groupLevel) => {
  if (groupLevel.maxBlocks === 2) {
    const avgQuotum = mean(blocks.map(b => b.quotum))

    let rotations = []
    blocks.forEach(b => {
      const index = b.block.index
      let rotation = rotations.find(r => r.index === index)
      if (! rotation) {
        rotation = {
          index,
          blocks: [],
          quotum: 0,
        }
        rotations.push(rotation)
      }

      rotation.blocks.push(b)
      rotation.quotum += b.restQuotum
    })

    rotations = sortBy(rotations, 'index')
    let blockSelection = []

    if (size <= avgQuotum / 3) {
      // filter rotations by capacity
      const selectedRotations = rotations.filter(r => r.quotum >= size)
      console.log(rotations)

      if (selectedRotations.length) {
        const randomIndex = Math.floor(Math.random() * selectedRotations.length)
        const rotation = selectedRotations[randomIndex]
        blockSelection = rotation.blocks
      }
    }
    if (size > avgQuotum / 3 || ! blockSelection.length) {
      // spread over groups in two consecutive rotations
      // find block index matching sufficient capacity
      rotations = rotations.filter(rotation => {
        let capacity = rotation.quotum
        const nextRotation = rotations.find(r => r.index === rotation.index + 1)
        if (nextRotation) {
          capacity += nextRotation.quotum
        }
        return capacity >= size
      })
      blockSelection = rotations.reduce((selection, rotation) => selection.concat(rotation.blocks), [])
    }
    const assignedQuota = assignBlockQuota(size, blockSelection)
    return assignedQuota
  }

  if (groupLevel.randomiseGroupOrder) {
    // Find the list of blocks that can fit the group
    let blockSelection = blocks.filter(b => b.restQuotum >= size)
    if (! blockSelection.length) {
      // find blocks with most places left
      const maxPlaces = blocks.reduce((max, block) => Math.max(max, block.restQuotum), 0)
      blockSelection = blocks.filter(b => b.restQuotum === maxPlaces)
    }

    // pick random block from selection
    const randomIndex = Math.floor(Math.random() * blockSelection.length)
    const block = blockSelection[randomIndex]
    let quotum = Math.min(block.restQuotum, size)
    if (groupLevel.keepTogether) {
      quotum = size
    }
    return [{
      block,
      quotum,
    }]
  }

  // find first block with space
  const block = blocks.find(b => b.restQuotum > 0)
  return [{
    block,
    quotum: Math.min(block.restQuotum, size)
  }]
}

const assignBlockQuota = (quotum, blocks) => {
  const perBlockQuotum = Math.ceil(quotum / blocks.length)
  let restQuotum = quotum
  let assigned = []

  let remaining = shuffleArray(blocks)

  remaining = remaining.filter(b => {
    if (b.restQuotum < perBlockQuotum) {
      assigned.push({
        block: b,
        quotum: b.restQuotum,
      })
      restQuotum -= b.restQuotum
      return false
    }
    return true
  })

  if (assigned.length) {
    assigned = assigned.concat(assignBlockQuota(restQuotum, remaining))
  } else {
    assigned = remaining.map(b => {
      const assigning = Math.min(restQuotum, perBlockQuotum)
      restQuotum -= assigning

      return {
        block: b,
        quotum: assigning,
      }
    })
  }

  return assigned
}

const getSessionStartTime = (session) => {
  if (session.timeTable) {
    const start = session.timeTable.find(i => i.key === 'competition')
    if (start) {
      return moment(start.time, 'HH:mm')
    }
  }
  return null
}

export {
  shuffleArray,
  getParticipationClub,
  removeMetaDataFromParticipations,
  getSessionBlocks,
  selectBlocks,
  getSessionStartTime,
}
